<!--
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-03-08 17:39:39
 * @LastEditTime: 2021-04-03 18:41:01
-->
<template>
  <div class="singleOrder"  @click="queryDetail(orderInfo)">
    <div class="personInfo">
      <div><span>单位名称：</span><span>{{ orderInfo.companyName }}</span></div>
      <div><span>单位地址：</span><span>{{ orderInfo.companyAddress }}</span></div>
      <div><span>联系方式：</span><span @click.stop="" @click="getAxb(orderInfo)" style="color: blue">{{ `联系单位` }}</span>
      </div>
      <div><span>工种：</span><span>{{ orderInfo.workName }}</span></div>
      <div>
        <div><span>服务时间：</span><span>{{ orderInfo.workTime }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, toRefs} from 'vue'
import {Dialog} from "vant";
import req from "../../request/request";
import API_ORDER from "../../api/Order";
import router from "../../router";
import API_USER from "@/api/UserReq";

export default {
  name: 'orderCompanyCard',
  components: {},
  props: {
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    active: {
      type: Number,
      default: () => {
        return 10
      }
    },
  },
  setup() {


    let data = reactive({});

    let methodMap = {
      queryDetail: (orderInfo) => {
        router.push('/OrderDetail/' + orderInfo.orderId)
      },
      getAxb: (item) => {
        let reqJson = {
          "companyUid": item.companyUid,
          "workerUid": JSON.parse(localStorage.getItem("userinfo")).uid,
        }
        API_USER.getSecretNo(reqJson)
      },

    }


    return {
      ...toRefs(data),
      ...methodMap
    }
  }
}
</script>

<style lang="less">
.singleOrder {
  background: #FFF;
  margin: 5px 7px;
  padding: 10px;
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 80px;

  .personInfo {
    position: absolute;
    left: 8px;
    font-size: 11px;

    span {
      padding: 2px;
    }

    .orderDiv {
      color: gray;
    }
  }
}

</style>