<template>
  <div class="singleOrder" @click="queryDetail(orderInfo)">
    <div class="personInfo">
      <div><span>姓名：</span><span>{{ orderInfo.name }}</span></div>
      <div><span>编号：</span><span>{{ orderInfo.workerNo }}</span></div>
      <!--      <div><span>联系方式：</span><span><a :href="'tel:'+orderInfo.phone" @click.stop="">{{ orderInfo.phone }}</a></span></div>-->
      <div><span>联系方式：</span><span @click.stop="" @click="getAxb(orderInfo)" style="color: blue">{{ `联系工人` }}</span>
      </div>
      <div>
        <div v-show="orderInfo.orderStatus == 3"><span>到达时间：</span><span>{{ orderInfo.updateTime }}</span></div>
        <div v-show="orderInfo.orderStatus == 4"><span>完成时间：</span><span>{{ orderInfo.updateTime }}</span></div>
      </div>
      <div><span>服务时间：</span><span>{{ orderInfo.workTime }}</span></div>
    </div>
    <div class="handle">
      <!--      <van-button round type="primary" size="mini" @click="confirm(orderInfo)"-->
      <!--                  >-->
      <!--        {{-->
      <!--          orderInfo.orderStatus == 0 ? '确认到达' : orderInfo.orderStatus == 3 ? '确认完成' : orderInfo.orderStatus == 4 ? '评价' : ''-->
      <!--        }}-->
      <!--      </van-button>-->

      <van-tag @click="confirm(orderInfo)" @click.stop="" color="red" type="danger" round size="medium"
               v-if="orderInfo.orderStatus== 0 || orderInfo.orderStatus == 3 || orderInfo.orderStatus == 4">{{
          orderInfo.orderStatus == 0 ? '确认到达' : orderInfo.orderStatus == 3 ? '确认完成' : orderInfo.orderStatus == 4 ? '去评价' : ''
        }}
      </van-tag>

      <van-tag v-else-if="orderInfo.orderStatus < 0" plain color="gray" size="medium">已取消</van-tag>
      <van-tag v-else plain type="success" size="medium">已完成</van-tag>
    </div>
  </div>

</template>

<script>
import {reactive, toRefs} from 'vue'
import {Dialog} from "vant";
import req from "../../request/request";
import router from "../../router";
import API_USER from "../../api/UserReq";
import API_ORDER from "../../api/OrderReq";

export default {
  name: 'orderCard',
  components: {},
  props: {
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    active: {
      type: Number,
      default: () => {
        return 10
      }
    },
  },
  setup() {


    let data = reactive({
    });

    let methodMap = {

      getAxb: (item) => {
        let reqJson = {
          "companyUid": JSON.parse(localStorage.getItem("userinfo")).uid,
          "workerUid": item.uid,
        }
        API_USER.getSecretNo(reqJson)
      },

      queryDetail: (orderInfo) => {
        router.push('/OrderDetail/' + orderInfo.orderId)
      },


      // 右边返回
      confirm: (item) => {
        if (item.orderStatus == 0) {
          Dialog.confirm({
            title: '订单',
            message: '是否确认到达？',
          }).then(() => {
            let json = {
              status: 3,
              orderId: item.orderId
            }
            API_ORDER.handle(json)

          }).catch(() => {
            // on cancel
          });
        } else if (item.orderStatus == 3) {
          Dialog.confirm({
            title: '订单',
            message: '是否确认完成？',
          }).then(() => {
            let json = {
              status: 4,
              orderId: item.orderId
            }
            API_ORDER.handle(json)
          }).catch(() => {
            // on cancel
          });

        } else if (item.orderStatus == 4) {
          router.push("/OrderEvaluate/" + item.orderId)

        }
      }
    }


    return {
      ...toRefs(data),
      ...methodMap
    }
  }
}
</script>

<style lang="less">


.singleOrder {
  background: #FFF;
  margin: 5px 7px;
  padding: 10px;
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 80px;

  .personInfo {
    position: absolute;
    left: 8px;
    font-size: 11px;

    span {
      padding: 2px;
    }

    .orderDiv {
      color: gray;
    }
  }

  .handle {
    position: absolute;
    right: 8px;

  }
}

</style>