<template>
  <div class="register-select app_content">
    <div class="warp_body">

      <van-tabs v-model:active="active" color="#1989fa" @click-tab="getOrderList()" class="orderTabs" sticky
                background="#f4f4f4" title-active-color="#1989fa">
        <van-tab v-for="(item) in orderType" :title="item.name" :key="item" :name="item.orderStatus">
          <div v-for="(item2) in orderList" v-bind:key="item2">
            <order-card :orderInfo="item2"
                        v-if="showPersonCard"></order-card>
            <order-company-card v-else :orderInfo="item2"></order-company-card>
          </div>
        </van-tab>
      </van-tabs>
      <van-empty description="暂无订单" v-show="vanEmpty"/>

    </div>

  </div>
</template>

<script>

import {onBeforeMount, onMounted, onUpdated, reactive, toRefs} from 'vue'
import API_ORDER from "../api/Order";
import req from "../request/request";
import router from "../router";
import OrderCard from "../components/common/orderCard";
import {useRoute} from "vue-router";
import OrderCompanyCard from "../components/common/orderCompanyCard";


export default {
  name: 'OrderList',
  components: {OrderCompanyCard, OrderCard},

  setup() {
    onBeforeMount(() => {

      data.active = useRoute().query.active ? Number(useRoute().query.active) : data.active
      methodsMap.getOrderType()
      methodsMap.reqOrderList()
      console.log(useRoute().query.active)
      data.showPersonCard = JSON.parse(localStorage.getItem('userinfo')).userType.toLowerCase() == 'c'
      // data.active = useRoute().query.active
    });
    const data = reactive({
          showPersonCard: false,
          vanEmpty: true,
          active: 10,
          orderType: [],
          orderList: [],
        },
    )

    const methodsMap = {
      getOrderType: () => {
        req.request(API_ORDER.orderType, null, {method: 'GET'}).then((res) => {
          let list = res.data.data
          list.forEach((d) => {
            if (d.code == 'all') {
              d.name = '全部'
            }
            data.orderType.push(d)

          })
        })
      },
      getOrderList: () => {
        console.log(data.active)
        // window.location.href = router.currentRoute.value.path + "?active=" + data.active
        // router.push({path: router.currentRoute.value.path, query: {active: data.active}})
        data.orderList = []
        methodsMap.reqOrderList()
      },
      reqOrderList: () => {
        data.vanEmpty = true
        req.request(API_ORDER.orderList, {status: data.active}, {method: 'GET'}).then((res) => {
          let list = res.data.data
          if (list.length > 0) {
            data.vanEmpty = false
          }
          list.forEach((d) => {
            data.orderList.push(d)
          })
        })
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {

    .orderTabs {
      //--van-tabs-nav-background-color: #f4f4f4;
    }
  }
}
</style>
